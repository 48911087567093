import React, {useEffect, useState} from "react";
import { Link as RouterLink } from "react-router-dom";
import {
    Box,
    Button,
    Flex,
    Input,
    Heading,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Link,
    LinkBox,
    LinkOverlay,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import { ChakraProvider } from '@chakra-ui/react'

//https://fastapi-example-yzfs.onrender.com/todo https://fastapi-production-7592.up.railway.app/todo
const remoteHost = "https://web-production-762ec.up.railway.app/https://fastapi-production-7592.up.railway.app/todo"

const TodosContext = React.createContext({
  todos: [], fetchTodos: () => {}
})

function AddTodo() {
  const [item, setItem] = React.useState("")
  const {todos, fetchTodos} = React.useContext(TodosContext)

  const handleInput = event  => {
    setItem(event.target.value)
    const newTodo = {
      "id": todos.length + 1,
      "item": event.target.value
    }
    fetch(remoteHost, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newTodo)
    }).then(fetchTodos)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setItem(event.target.value)
    const newTodo = {
      "id": todos.length + 1,
      "item": item
    }
    fetch(remoteHost, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newTodo)
    }).then(fetchTodos)
  }

  const pl = ["test1", "test2", "test3"]
  const time = (todos[todos.length - 1])
  return (
    <form onSubmit={handleSubmit}>
    <Stack spacing={20}>
    <Flex align="center">
    <Box p={3} borderWidth='0px' mx="auto" my = "0px"width={{ base: "95%", md: "70%", lg: "70%" }}>
      <InputGroup size="md">
        <Input
          pr="4.5rem"
          type="text"
          placeholder="Search a course.."
          aria-label="Search a course.."
          onChange={handleInput}
        />
      </InputGroup>
      <Text fontSize="xs" fontFamily="Arial" textAlign="center" my="10px">
          potential searches: "classical european poetry" or "how do I design a website?"
        </Text>
      </Box>
            </Flex>
      </Stack>
    </form>
  )
}

function TodoHelper({item, id, desc, units, ge, dept, fetchTodos}) {
  const string1 = "https://catalog.ucdavis.edu/courses-subject-code/" + dept;
  return (
    <Box p={4} shadow="md" borderWidth='0.5px' borderRadius='md'>
      <Text color="#192a56" fontSize="lg" fontFamily="Arial" fontWeight="bold" mx="10px" my="10px">
          {item}: {id}
        </Text>
        <Text fontSize="sm" fontFamily="Arial" mt={2} mx="10px" mb = "10px">
          {desc}
          </Text>
          <Flex direction="row">
            <Box p={3} borderWidth='1.5px' borderRadius='md' padding={0} maxWidth="fit-content" px= "10px" mx = "10px">
              <Text fontSize="xs" fontFamily="Arial" m = "1px">
              {units} unit(s)
              </Text>
            </Box>

            {ge.map((string, index) => (
              <Box key={index} borderWidth='1.5px' borderRadius='md' padding={0} maxWidth="fit-content" px= "10px" px= "10px" mx = "2px">
              <Text fontSize="xs" fontFamily="Arial" m = "1px">
              {string}
              </Text>
              </Box>
            ))}

            </Flex>
            <Link href={string1} isExternal>
            <Button colorScheme="blue" _hover={{ bg: "rgba(39, 60, 170, 0.5)" }} focusBorderColor="#273c75" borderWidth='1.5px' bg="white" color="black" fontSize="xs" fontFamily="Arial" my = "10px" py = "5px" px= "10px" px= "10px" mx = "10px">
              view on general catalog &#128269;
            </Button>
            </Link>
    </Box>
  )
}

export default function Todos() {
  const [todos, setTodos] = useState([])
  const fetchTodos = async () => {
    const response = await fetch(remoteHost)
    const todos = await response.json()
    setTodos(todos.data)
  }
  if (todos.length > 1) {
    return (
      <TodosContext.Provider value={{todos, fetchTodos}}>
        <AddTodo/>
        <Flex justifyContent="center">
        <Box borderWidth='0px' borderRadius='md' padding={0} maxWidth="fit-content" px= "5px" mx = "10px" bg="rgba(39, 60, 170, 0.2)">
        <Text fontSize="xs" fontFamily="Arial" textAlign="center" my="3px" mx="3px" fontWeight="bold">
          found results in {(todos[todos.length - 1].units.toFixed(4))} seconds
        </Text>
        </Box>
        </Flex>
        <Flex justifyContent="center">
        <Box p={3} borderWidth='0px' width={{ base: "95%", md: "70%", lg: "70%" }} my = "10px">
        <Stack spacing={5}>
          {
            todos.slice(0, todos.length - 1).map((todo) => (
              <TodoHelper item={todo.code} id={todo.title} desc = {todo.description} units = {todo.units} ge = {todo.ge} dept = {todo.department} fetchTodos={fetchTodos} />
            ))
          }
        </Stack>
        </Box>
        </Flex>
      </TodosContext.Provider>
    )
  }
  return (
    <TodosContext.Provider value={{todos, fetchTodos}}>
      <AddTodo/>
              <Flex justifyContent="center">
      <Box p={3} borderWidth='0px' width={{ base: "95%", md: "70%", lg: "70%" }} mx="200px" my = "10px">
      <Stack spacing={5}>
        {
          todos.slice(0, todos.length - 1).map((todo) => (
            <TodoHelper item={todo.code} id={todo.title} desc = {todo.description} units = {todo.units} ge = {todo.ge} dept = {todo.department} fetchTodos={fetchTodos} />
          ))
        }
      </Stack>
      </Box>
      </Flex>
    </TodosContext.Provider>
  )
}
