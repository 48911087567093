import React from "react";
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from "@chakra-ui/react";
import {
    Box,
    Button,
    Flex,
    Input,
    Heading,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Link,
    Text,
    useDisclosure
} from "@chakra-ui/react";

import Header from "./components/Header";
import Todos from "./components/Todos";

function App() {
  return (
    <ChakraProvider>
    <Box p={3} borderWidth='0px' mx="0px" my = "10px" >
    <Text color="#192a56" mt = "80px" fontSize="6xl" fontFamily="Arial" textAlign="center" fontWeight="bold">
        eleven
      </Text>
      <Text fontSize="lg" fontFamily="Arial" textAlign="center" fontWeight="bold">
          an intelligent course catalog for uc davis students 	&#128218;
        </Text>
    </Box>
      <Header />
      <Link href="https://vishalshenoy.com/" isExternal>
      <Text fontSize="xs" fontFamily="Arial" textAlign="center" my = "10px">
        built by vishal
        </Text>
        </Link>
    </ChakraProvider>
  )
}

const container = document.getElementById("root")
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />)
